import React from 'react';
import useDataGridStyles from './DataGrid.style';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import eyeIcon from '../../../assets/eyeIcon.svg';
import doneIcon from '../../../assets/done.svg';
import checkmark from '../../../assets/check-mark.svg';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ClearIcon from '@mui/icons-material/Clear';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGQLMutation } from '../../../hooks/useGQLMutation';
import userQueries from '../../../queries/adminDashboard';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { URL_CONF, KEY_CONF } from '../../../helper/constants';
import toast from 'react-hot-toast';
import { QueryErrorResponse, UserAccountInfo } from '../../../types/UserDetailType';
import { PATH_ADMINPANEL } from '../../../routers/path';

interface ActionsMenuProps {
    openDetailPopup: () => void;
    openUpdateStatus?:() => void;
    handleClickStatusOpen?: () => void;
    rowData?: UserAccountInfo | null;
    refetch?: () => void;
    openStatusPopup?: () => void;
    valuee?: number;
    setLoading?: (data: boolean) => void;
}

function ActionsMenu({
    openDetailPopup, rowData, refetch, openStatusPopup, valuee, setLoading, openUpdateStatus, handleClickStatusOpen,
}: ActionsMenuProps) {
    const classes = useDataGridStyles();
    // const params = useParams();
    const activeUserLibraryData = useSelector((state: RootState) => state.userLibrary.value);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const location = useLocation();
    const navigate = useNavigate();
    const url = PATH_ADMINPANEL.children.library;
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const indexValue = 1;
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        if (message) {
            toast.error(message);
        }
    };

    const userId = activeUserLibraryData?.id || '';
    const isActive = !activeUserLibraryData?.is_active;
    const { mutate } = useGQLMutation(
        KEY_CONF.ADMIN_CHANGE_USER_STATUS,
        userQueries.ADMIN_CHANGE_STATUS(userId, isActive),
        {
            onSuccess: () => {
                if (refetch) {
                    refetch();
                }
                if (setLoading) {
                    setLoading(false);
                }
            },
            onError: handleApiError,
            enabled: false,
        },
        URL_CONF.ADMIN,
    );

    // API call to change the verified status
    const isVerified = !(activeUserLibraryData?.sumsub_verified && activeUserLibraryData?.teller_verified);
    const { mutate: markAsVerified } = useGQLMutation(
        'verifiedStatus',
        userQueries.ADMIN_CHANGE_VERIFIED(userId, isVerified),
        {
            onSuccess: () => {
                if (refetch) {
                    refetch();
                }
                if (setLoading) {
                    setLoading(false);
                }
            },
            onError: handleApiError,
            enabled: false,
        },
        URL_CONF.ADMIN,
    );

    const handleViewMore = () => {
        openDetailPopup();
        if (location.pathname === `${url}` && rowData) {
            navigate(`${url}/${rowData.id}`);
        }
        handleClose();
    };

    const handleStatus = () => {
        if (openStatusPopup) {
            openStatusPopup();
        }
        if (openUpdateStatus) {
            openUpdateStatus();
        }
        if (handleClickStatusOpen) {
            handleClickStatusOpen();
        }
    };

    const handleActive = () => {
        const usersId = activeUserLibraryData?.id || '';
        const isActiveUser = !activeUserLibraryData?.is_active;
        mutate({ usersId, isActiveUser });
        if (setLoading) {
            setLoading(true);
        }
    };

    const handleActiveStatus = () => {
        const usersId = activeUserLibraryData?.id || '';
        const isActiveUser = !activeUserLibraryData?.is_active;
        mutate({ usersId, isActiveUser });
        if (setLoading) {
            setLoading(true);
        }
    };

    /**
     * Function to trigger the status api
     * @param
     * @returns
     */
    const handleMarkAsStatus = () => {
        const usersId = activeUserLibraryData?.id || '';
        markAsVerified({ usersId, isVerified });
    };

    return (
        <>
            <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <MoreHorizIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                className={classes.actionMenu}
                PaperProps={{
                    elevation: 0,
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleViewMore}>
                    <img src={eyeIcon} alt={eyeIcon} />
                    View More Details
                </MenuItem>
                {valuee === indexValue ? (
                    <MenuItem onClick={handleActiveStatus}>
                        <img src={doneIcon} alt={doneIcon} />
                        Mark as Active
                    </MenuItem>
                ) : null}
                {valuee !== indexValue && (location.pathname === `${url}`
                    ? (
                        <div>
                            {!(rowData?.sumsub_verified && rowData?.teller_verified) && (
                                <MenuItem
                                    onClick={handleMarkAsStatus}
                                >
                                    <img src={checkmark} alt={checkmark} />
                                    Mark as Verified
                                </MenuItem>
                            )}
                            <MenuItem onClick={handleActive}>
                                <span><ClearIcon /></span>
                                Mark as Inactive
                            </MenuItem>
                        </div>
                    )
                    : (
                        <MenuItem onClick={handleStatus}>
                            <img src={doneIcon} alt={doneIcon} />
                            Update Status
                        </MenuItem>
                    ))}
            </Menu>
        </>
    );
}

export default ActionsMenu;
