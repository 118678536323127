import { ITradeOrderFormProps } from '../../../interfaces/User';
import {
    Box,
    Button,
    Checkbox,
    FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputAdornment, InputLabel,
    MenuItem, Select, TextField, Typography,
} from '@mui/material';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import tradeOrderCss from './TradeOrder.style';
import PhoneInput from 'react-phone-input-2';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useState } from 'react';
import QrReader from './BarCodeScanner';

const blockchainList = [
    { name: 'Ethereum ERC20', value: 'ethereum' },
    { name: 'Solana SOL', value: 'solana' },
];

const transactionType = [
    { id: 1, value: 'buy', label: 'Buy Crypto (Sell Fiat)' },
    { id: 2, value: 'sell', label: 'Sell Crypto (Buy Fiat)' },
];

const cryptoTypes = [
    { name: 'USDC', value: 'usdc' },
    { name: 'USDT', value: 'usdt' },
];

function TradeForm(props: ITradeOrderFormProps) {
    const {
        tradeState,
        errorState,
        handleTextfieldChange,
        handleContactChange,
        handleSubmit,
        handleCheckboxChange,
        handleListItemChange,
        walletCreationError,
    } = props;
    const classes = tradeOrderCss();
    const [openBarcode, setOpenBarcode] = useState(false);

    const handleScan = (data: string) => {
        tradeState.walletAddress = data;
        setOpenBarcode(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            {openBarcode && (
                <div className={classes.scannerWrapper}>
                    <QrReader handleScanSuccess={handleScan} />
                    <Button variant="outlined" type="submit" onClick={() => setOpenBarcode(false)}>Stop Scanner</Button>
                </div>

            ) }
            {!openBarcode
            && (
                <Grid container className={classes.sectionBody}>
                    <Grid item xs={12} md={12}>
                        <Box className={classes.rightBodyInput}>
                            <InputLabel>
                                What would you like to do today?*
                            </InputLabel>
                            <FormControl fullWidth error={!!errorState.tradeAction}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={tradeState.tradeAction}
                                    className=" select"
                                    size="small"
                                    onChange={handleListItemChange}
                                    defaultValue="buy"
                                    name="tradeAction"
                                >
                                    {transactionType.map((type) => (
                                        <MenuItem value={type.value} key={type.id}>{type.label}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errorState?.tradeAction}</FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box className={classes.rightBodyInput}>
                            <InputLabel>
                                Select the crypto you would like to
                                {' '}
                                {tradeState.tradeAction}
                                *
                            </InputLabel>
                            <FormControl fullWidth error={!!errorState.cryptoType}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    size="small"
                                    value={tradeState.cryptoType}
                                    name="cryptoType"
                                    onChange={handleListItemChange}
                                    defaultValue="Sell Crypto (Recieve Fiat)"
                                >
                                    { cryptoTypes.map((item) => <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>) }
                                </Select>
                                <FormHelperText>{errorState?.cryptoType}</FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box className={classes.rightBodyInput}>
                            <InputLabel>
                                Select the blockchain*
                            </InputLabel>
                            <FormControl fullWidth error={!!errorState.blockchain}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    size="small"
                                    value={tradeState.blockchain}
                                    name="blockchain"
                                    onChange={handleListItemChange}
                                >
                                    {blockchainList.map((item) => (
                                        <MenuItem
                                            key={item.value}
                                            value={item.value}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errorState?.blockchain}</FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>

                    <Box className={classes.buyCryptoStyle}>
                        <Grid item xs={12} md={12}>
                            <Box className={classes.rightBodyInput}>
                                <InputLabel>
                                    Enter your wallet address*
                                </InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    value={tradeState.walletAddress}
                                    name="walletAddress"
                                    onChange={handleTextfieldChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" onClick={() => setOpenBarcode(true)}>
                                                <ContentPasteIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={!!errorState.walletAddress}
                                    helperText={errorState.walletAddress}
                                />
                                <span className={classes.warningStyle}>
                                    <WarningAmberIcon />
                                    {walletCreationError || (
                                        'Please verify that the wallet address you provided is for the right token.'
                                    )}
                                </span>
                            </Box>
                        </Grid>
                    </Box>

                    <Grid container className={classes.enterAmountStyle}>
                        <Grid item xs={12} sm={5}>
                            <Box className={classes.rightBodyInput}>
                                <InputLabel>
                                    Enter amount
                                </InputLabel>
                                <TextField
                                    size="small"
                                    value={tradeState.tradeAmount}
                                    name="tradeAmount"
                                    onChange={handleTextfieldChange}
                                    fullWidth
                                    disabled={!!tradeState.tradeToken}
                                    error={!!errorState.tradeAmount}
                                    helperText={errorState.tradeAmount}
                                />
                            </Box>
                        </Grid>
                        <Grid className={classes.xsnone} item xs={12} sm={2}>
                            <Typography
                                variant="body2"
                                className={classes.orStyleCenter}
                            >
                                OR
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Box className={classes.rightBodyInputTokens}>
                                <InputLabel>
                                    Quantity of Tokens
                                </InputLabel>
                                <TextField
                                    size="small"
                                    fullWidth
                                    onChange={handleTextfieldChange}
                                    value={tradeState.tradeToken}
                                    name="tradeToken"
                                    disabled={!!tradeState.tradeAmount}
                                    error={!!errorState.tradeToken}
                                    helperText={errorState.tradeToken}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box className={`${classes.rightBodyInput} ${classes.telephoneBoxStyle}`}>
                            <InputLabel>
                                Phone Number*
                            </InputLabel>
                            <PhoneInput
                                country="us"
                                value={tradeState.phoneNumber}
                                onChange={handleContactChange}
                            />
                            <span className={classes.errorStyle}>{errorState?.phoneNumber}</span>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box className={classes.rightBodyInput}>
                            <InputLabel>
                                Email*
                            </InputLabel>
                            <TextField
                                size="small"
                                fullWidth
                                value={tradeState.email}
                                onChange={handleTextfieldChange}
                                name="email"
                                placeholder="Enter the email"
                                error={!!errorState.email}
                                helperText={errorState.email}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box className={`${classes.rightBodyInput} ${classes.rightBodyInputCheckbox}`}>
                            <InputLabel>
                                What is your preferred method of contact?*
                            </InputLabel>
                            <FormControl component="fieldset" className={classes.radioButton}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox checked={tradeState.contactMethod === 'Text Message'} onChange={handleCheckboxChange} />}
                                        label="Text Message"
                                        name="Text Message"
                                        className={classes.radio}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={tradeState.contactMethod === 'Whatsapp'} onChange={handleCheckboxChange} />}
                                        label="Whatsapp"
                                        name="Whatsapp"
                                        className={classes.radio}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={tradeState.contactMethod === 'Telegram'} onChange={handleCheckboxChange} />}
                                        label="Telegram"
                                        name="Telegram"
                                        className={classes.radio}
                                    />
                                </FormGroup>
                                <span className={classes.errorStyle}>{errorState?.contactMethod}</span>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                            className={classes.BtnConf}
                        >
                            Confirm & Proceed
                        </Button>
                    </Grid>
                </Grid>
            )}
        </form>
    );
}

export default TradeForm;
