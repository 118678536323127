import { gql } from 'graphql-request';

const SAVE_DRAFT = () => gql`
  mutation ($myData: RequestInterface!) {
    save_as_draft(myData: $myData) {
      message
    }
  }
`;

const USER_DRAFT_DATA = () => gql`
  query {
    get_draft_data {
      message
      basicInfoData {
        user_info {
          first_name
          last_name
          settlement_currency
          dob
          charity_industry
          business_type
          business_industry
          org_name
          website
          tax_id
          org_number
          representative_contact,
          representative_contact_country_code,
          representative_email,
        }
        address {
          address_line_1
          address_line_2
          country
          city
          state
          postal_code
        }
      }
      disableUserData,
      googleSSOUserContact
      accountData {
        is_manually_created
        account_holder_email
        account_holder_first_name
        account_holder_last_name
        beneficiary_name
        beneficiary_address
        currency
        routing_number
        wire_routing_number
        transit_number
        institute_number
        account_number
        account_name
        account_description
        banking_instructions
        bank_address
        swift_bic
        account_confirmation
      }
    }
  }
`;

const OTC_ACCOUNT_DATA = () => gql`
  query {
    get_otc_account_data {
      message
      basicInfoData {
        user_info {
          first_name
          last_name
          settlement_currency
          dob
          charity_industry
          business_type
          business_industry
          org_name
          website
          tax_id
          org_number
          representative_contact,
          representative_contact_country_code,
          representative_email,
        }
        address {
          address_line_1
          address_line_2
          country
          city
          state
          postal_code
        }
      }
      googleSSOUserContact
      accountData {
        is_manually_created
        account_holder_email
        account_holder_first_name
        account_holder_last_name
        beneficiary_name
        beneficiary_address
        currency
        routing_number
        transit_number
        institute_number
        account_number
        account_name
        account_description
        banking_instructions
        bank_address
        swift_bic
      }
    }
  }
`;

const USER_ACCOUNT_INFO = () => gql`
  query {
    user_info {
      message
      userInfoData {
        role
        first_name
        last_name
        dob
        settelment_currency
        charity_industry
        business_type
        business_industry
        org_name
        website
        tax_id
        org_number
      }
    }
  }
`;

export default {
    SAVE_DRAFT,
    USER_DRAFT_DATA,
    USER_ACCOUNT_INFO,
    OTC_ACCOUNT_DATA,
};
