import {
    PATH_ADMINPANEL, PATH_AUTH, PATH_ONBOARDING, PATH_OTC, PATH_PROFILE,
} from '../routers/path';
import { KEY_CONF } from './constants';

const PAGE_ACTION = {
    ADD: 'page-add',
    ACCESS: 'page-access',
    UPDATE: 'page-update',
};

const logObj = [
    // ------------------------Auth-------------------------------- //
    {
        key: KEY_CONF.REGISTER, // Signup
        access_info: 'User signs up (Personal, Business, Charity)',
        user_action: PAGE_ACTION.ADD,
        page_url: PATH_AUTH.children.signup,
    },
    {
        key: KEY_CONF.AUTHENTICATION_GOOGLE_SIGNUP, // Signup Google
        access_info: 'User signs up via Google (Personal, Business, Charity)',
        user_action: PAGE_ACTION.ADD,
        page_url: PATH_AUTH.children.signup,
    },
    {
        key: KEY_CONF.USERS, // Login
        access_info: 'User logs in',
        user_action: PAGE_ACTION.ACCESS,
        page_url: PATH_AUTH.children.login,
    },
    {
        key: KEY_CONF.AUTHENTICATION_GOOGLE_LOGIN, // Login Google
        access_info: 'User logs in via Google',
        user_action: PAGE_ACTION.ACCESS,
        page_url: PATH_AUTH.children.login,
    },
    {
        key: KEY_CONF.FORGOT_PASSWORD, // Forgot password
        access_info: 'User resets password',
        user_action: PAGE_ACTION.ACCESS,
        page_url: PATH_AUTH.children.forgotPassword,
    },
    // ------------------------Onboarding-------------------------------- //
    {
        key: KEY_CONF.REFRESH_TOKEN, // Refresh token
        access_info: 'User refreshes token',
        user_action: PAGE_ACTION.ACCESS,
    },
    {
        key: KEY_CONF.GET_DRAFT_DATA, // Get onboarding details
        access_info: 'User retrieves draft data',
        user_action: PAGE_ACTION.ACCESS,
        page_url: PATH_ONBOARDING.root,
    },
    {
        key: KEY_CONF.SAVE_DRFT_DATA, // Update onboarding data
        access_info: 'User updates draft data',
        user_action: PAGE_ACTION.UPDATE,
        page_url: PATH_ONBOARDING.root,
    },
    {
        key: KEY_CONF.SUMSUB_DETAILS_TOKEN, // Sumsub Verify
        access_info: 'User retrieves Sumsub token',
        user_action: PAGE_ACTION.ACCESS,
        page_url: PATH_ONBOARDING.root,
    },
    {
        key: KEY_CONF.SUMSUB_DETAILS, // Sumsub Verify
        access_info: 'User retrieves Sumsub details',
        user_action: PAGE_ACTION.ACCESS,
        page_url: PATH_ONBOARDING.root,
    },
    {
        key: KEY_CONF.ADD_SUMSUB_STATUS, // Sumsub Verify
        access_info: 'User adds Sumsub details',
        user_action: PAGE_ACTION.ADD,
        page_url: PATH_ONBOARDING.root,
    },
    {
        key: KEY_CONF.TELLER_VERIFY, // Teller Verify
        access_info: 'User verifies teller',
        user_action: PAGE_ACTION.ADD,
        page_url: PATH_ONBOARDING.root,
    },
    {
        key: KEY_CONF.TOS, // Terms and Conditions
        access_info: 'User accesses Terms of Service',
        user_action: PAGE_ACTION.ACCESS,
        page_url: PATH_ONBOARDING.root,
    },
    // ------------------------OTC-------------------------------- //
    {
        key: KEY_CONF.USER_TRANSACTIONS, // User transactions
        access_info: 'User accesses OTC dashboard transactions',
        user_action: PAGE_ACTION.ACCESS,
        page_url: PATH_OTC.dashboard,
    },
    {
        key: KEY_CONF.TRADE_BUY, // Trade Buy
        access_info: 'User initiates trade buy',
        user_action: PAGE_ACTION.ADD,
        page_url: PATH_OTC.tradeorder,
    },
    {
        key: KEY_CONF.TRADE_SELL, // Trade Sell
        access_info: 'User initiates trade sell',
        user_action: PAGE_ACTION.ADD,
        page_url: PATH_OTC.tradeorder,
    },
    {
        key: KEY_CONF.ACCOUNT, // Account
        access_info: 'User accesses account',
        user_action: PAGE_ACTION.ACCESS,
        page_url: PATH_PROFILE.profile,
    },
    {
        key: KEY_CONF.REFERRAL_PROGRAM, // Referral Program
        access_info: 'User accesses referral program',
        user_action: PAGE_ACTION.ACCESS,
        page_url: PATH_OTC.partnerrefer,
    },
    {
        key: KEY_CONF.CHANGE_PASSWORD, // Change Password
        access_info: 'User updates password',
        user_action: PAGE_ACTION.UPDATE,
    },
    // ------------------------Admin-------------------------------- //
    {
        key: KEY_CONF.ADMIN_DASHBOARD, // Admin Dashboard
        access_info: 'User accesses admin dashboard',
        user_action: PAGE_ACTION.ACCESS,
        page_url: PATH_ADMINPANEL.children.dashboard,
    },
    {
        key: KEY_CONF.ADMIN_TRANDE_ORDER, // Admin Trade Order
        access_info: 'User updates trade order',
        user_action: PAGE_ACTION.UPDATE,
        page_url: PATH_AUTH.children.signup,
    },
    {
        key: KEY_CONF.USER_LIBRARY_ACTIVE, // Active Users
        access_info: 'User accesses active users',
        user_action: PAGE_ACTION.ACCESS,
        page_url: PATH_ADMINPANEL.children.library,
    },
    {
        key: KEY_CONF.USER_LIBRARY_INACTIVE, // Inactive Users
        access_info: 'User accesses inactive users',
        user_action: PAGE_ACTION.ACCESS,
        page_url: PATH_ADMINPANEL.children.library,
    },
    {
        key: KEY_CONF.USER_LIBRARY_DETAIL, // User Library Detail
        access_info: 'User accesses user details in user library',
        user_action: PAGE_ACTION.ACCESS,
        page_url: PATH_ADMINPANEL.children.userdetails,
    },
    {
        key: KEY_CONF.USER_LIBRARY_TRANSACTION, // Transaction Detail in User Library
        access_info: 'User accesses transaction details in user library',
        user_action: PAGE_ACTION.ACCESS,
        page_url: PATH_ADMINPANEL.children.userdetails,
    },
    {
        key: KEY_CONF.USER_LIBRARY_REFERRAL, // Referral in User Library
        access_info: 'User accesses referral in user library',
        user_action: PAGE_ACTION.ACCESS,
        page_url: PATH_ADMINPANEL.children.userdetails,
    },
];

export const fetchLogObj = (inputKey: string) => {
    const filterData = logObj.find((l) => (l.key === inputKey));
    return filterData;
};

export default logObj;
