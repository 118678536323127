import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import {
    Box,
    Divider,
    Grid,
    Tab, Tabs, Typography,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import {
    ReactNode, SyntheticEvent,
    useEffect,
    useState,
} from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BusinessInformationIcon from '../../assets/business_center.svg';
import CloseIcon from '../../assets/close_white.svg';
import DashboardIcon from '../../assets/dashboardu.svg';
import RepresentativeIcon from '../../assets/perm_identity.svg';
import LegalBusinessIcon from '../../assets/store_mall_directory.svg';
import UserLibraryIcon from '../../assets/useru.svg';
import Loader from '../../components/loader';
import { KEY_CONF, URL_CONF } from '../../helper/constants';
import { dateFormat, timeFormatterWithAmPm } from '../../helper/format';
import { statusHandleDetails } from '../../helper/gridCellHelper';
import { useGQLQuery } from '../../hooks/useGQLQuery';
import adminDashboard from '../../queries/adminDashboard';
import { accountInfoId, handleUserDetails } from '../../redux/reducer/userLibrary';
import { RootState } from '../../redux/store';
import { PATH_ADMINPANEL } from '../../routers/path';
import { UserDetailsData } from '../../types/UserDetailType';
import ListOfTransactionsTab from './pages/ListOfTransactionsTab';
import ReferralsTab from './pages/ReferralsTab';
import UserDetailsStyle from './UserDetails.style';

interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
  }

function CustomTabPanel(props: TabPanelProps) {
    const {
        children, value, index, ...other
    } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function UserDetails() {
    const classes = UserDetailsStyle();
    const [value, setValue] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [infoData, setInfoData] = useState<UserDetailsData | null>(null);
    const activeUserLibrary = useSelector((state: RootState) => state.userLibrary.key);
    const [emailToCopy, setEmailToCopy] = useState<string>('');

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const params = useParams();
    let userType;
    switch (infoData?.user_type) {
    case 1:
        userType = 'Personal';
        break;
    case 2:
        userType = 'Business';
        break;
    default:
        userType = 'Charity';
        break;
    }
    const handleClose = () => {
        if (activeUserLibrary === KEY_CONF.USER_LIBRARY_DATA) {
            navigate(PATH_ADMINPANEL.children.library);
            dispatch(handleUserDetails(''));
        } else {
            navigate(PATH_ADMINPANEL.children.dashboard);
        }
    };

    // const email = infoData?.email || '';
    // const [emailToCopy] = useState(email);

    useEffect(() => {
        setEmailToCopy(infoData?.email || '');
    }, [infoData]);

    const handleCopyClick = async () => {
        if (!emailToCopy) {
            toast.error('Email is not available');
            return;
        }
        try {
            await navigator.clipboard.writeText(emailToCopy);
        } catch (error) {
            toast.error('Unable to copy email');
        }
    };

    const userid = params?.userId || '';

    const { data: userDetails, isFetching, refetch: userDetailsRefetch } = useGQLQuery(
        KEY_CONF.SINGLE_USER_DETAIL,
        adminDashboard.SINGLE_USER_DETAILS(userid),
        {},
        {
            refetchOnWindowFocus: false,
        },
        URL_CONF.ADMIN,
    );

    useEffect(() => {
        userDetailsRefetch();
    }, [userDetailsRefetch, value]);

    useEffect(() => {
        if (userDetails && userDetails?.singleUserDetails !== undefined) {
            setInfoData(userDetails?.singleUserDetails);
            const detailsData = {
                id: userDetails?.singleUserDetails?.id,
                is_active: userDetails?.singleUserDetails?.is_active as boolean,
                self_referral_code: userDetails?.singleUserDetails?.self_referral_code as string,
                self_referral_code_name: userDetails?.singleUserDetails?.self_referral_code_name as string,
            };
            dispatch(accountInfoId(detailsData || {}));
        }
    }, [userDetails, dispatch]);

    // handle status of user
    const isActive = infoData?.is_active === true;
    const isVerified = typeof infoData?.pending_stage === 'string' && infoData?.pending_stage === 'verified';
    let className;
    if (isActive && isVerified) {
        className = classes.inBoxText;
    } else if (isActive) {
        className = classes.inBoxStatus;
    } else {
        className = classes.inBoxInactiveStatus;
    }
    let status;
    if (isActive) {
        status = isVerified ? 'verified' : 'pending';
    } else {
        status = 'In Active';
    }

    return (
        <div>
            {isFetching && <Loader />}
            <Box className={classes.mainContainer}>
                <Box className={classes.mainOverlay}>
                    <Box className={classes.overlayLeftBar} onClick={handleClose}><img src={CloseIcon} alt={CloseIcon} /></Box>
                    <Box className={classes.leftBar}>
                        <img src={DashboardIcon} alt={DashboardIcon} />
                        <img src={UserLibraryIcon} alt={UserLibraryIcon} />
                    </Box>
                </Box>
                <Box className={classes.rightSection}>
                    <Box className={classes.mainSection}>
                        <Typography className={classes.mainHeaderText} variant="h2">
                            User Details -
                            {' '}
                            {infoData?.full_name}
                        </Typography>
                        {/* {activeUserLibrary !== 'userLibrary' ? (
                            <Typography
                                className={`${getStatusColor(infoData?.transaction_status ? infoData?.transaction_status : '')}`}
                                variant="h4"
                            >
                                {statusHandle(infoData?.transaction_status ? infoData?.transaction_status : '')}
                            </Typography>
                        ) : (
                            <Typography
                                className={className}
                                variant="h4"
                            >
                                {statusHandleDetails(status)}
                                {' '}
                            </Typography>
                        )} */}
                        <Typography
                            className={className}
                            variant="h4"
                        >
                            {statusHandleDetails(status)}
                            {' '}
                        </Typography>

                    </Box>
                    <Box>
                        <Box sx={{ width: 'calc(100vw - 110px)' }}>
                            <Box className={classes.tabBottom}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Account Info" {...a11yProps(0)} />
                                    <Tab label="List of Transactions" {...a11yProps(1)} />
                                    <Tab label="Referrals" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <Box className={classes.inTabRight}>
                                    <Grid container spacing={2} className={classes.sectionBody}>
                                        <Grid item xs={12}>
                                            <Typography className={classes.subHeaderText} variant="h2">
                                                <img src={BusinessInformationIcon} alt={BusinessInformationIcon} />
                                                {userType}
                                                {' '}
                                                Information
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className={classes.blueHead} variant="body1">
                                                Full Name
                                            </Typography>
                                            <Typography className={classes.greySubText} variant="body1">
                                                {infoData?.full_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography className={classes.copyButtonStyle} variant="body1">
                                                Email
                                            </Typography>
                                            <Box className={classes.copyButtonStyle}>
                                                <Typography className={classes.greySubText} variant="body1">
                                                    {infoData?.email}
                                                </Typography>
                                                <span>
                                                    <Tooltip title="Click to copy" placement="top">
                                                        <FileCopyOutlinedIcon
                                                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                                            onClick={handleCopyClick}
                                                        />
                                                    </Tooltip>
                                                </span>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography className={classes.blueHead} variant="body1">
                                                Phone Number
                                            </Typography>
                                            <Typography className={classes.greySubText} variant="body1">
                                                {infoData?.contact}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider />
                                <Box className={classes.inTabRight}>
                                    <Grid container spacing={2} className={classes.sectionBody}>
                                        <Grid item xs={12}>
                                            <Typography className={classes.subHeaderText} variant="h2">
                                                <img src={LegalBusinessIcon} alt={LegalBusinessIcon} />
                                                Personal Address
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className={classes.blueHead} variant="body1">
                                                Country
                                            </Typography>
                                            <Typography className={classes.greySubText} variant="body1">
                                                {infoData?.country}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography className={classes.blueHead} variant="body1">
                                                Address Line 1
                                            </Typography>
                                            <Typography className={classes.greySubText} variant="body1">
                                                {infoData?.address_line_1}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography className={classes.blueHead} variant="body1">
                                                Address Line 2
                                            </Typography>
                                            <Typography className={classes.greySubText} variant="body1">
                                                {infoData?.address_line_2}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} />
                                        <Grid item xs={2}>
                                            <Typography className={classes.blueHead} variant="body1">
                                                City
                                            </Typography>
                                            <Typography className={classes.greySubText} variant="body1">
                                                {infoData?.city}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography className={classes.blueHead} variant="body1">
                                                Province/State
                                            </Typography>
                                            <Typography className={classes.greySubText} variant="body1">
                                                {infoData?.state}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography className={classes.blueHead} variant="body1">
                                                Postal Code
                                            </Typography>
                                            <Typography className={classes.greySubText} variant="body1">
                                                {infoData?.postal_code}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider />
                                <Box className={classes.inTabRight}>
                                    <Grid container spacing={2} className={classes.sectionBody}>
                                        <Grid item xs={12}>
                                            <Typography className={classes.subHeaderText} variant="h2">
                                                <img src={RepresentativeIcon} alt={RepresentativeIcon} />
                                                Basic Information
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className={classes.blueHead} variant="body1">
                                                First Name
                                            </Typography>
                                            <Typography className={classes.greySubText} variant="body1">
                                                {infoData?.first_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography className={classes.blueHead} variant="body1">
                                                Last Name
                                            </Typography>
                                            <Typography className={classes.greySubText} variant="body1">
                                                {infoData?.last_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography className={classes.copyButtonStyle} variant="body1">
                                                Email
                                            </Typography>
                                            <Box className={classes.copyButtonStyle}>
                                                <Typography className={classes.greySubText} variant="body1">
                                                    {infoData?.email}
                                                </Typography>
                                                <span>
                                                    <Tooltip title="Click to copy" placement="top">
                                                        <FileCopyOutlinedIcon
                                                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                                            onClick={handleCopyClick}
                                                        />
                                                    </Tooltip>
                                                </span>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3} />
                                        <Grid item xs={2}>
                                            <Typography className={classes.blueHead} variant="body1">
                                                Primary Phone Number
                                            </Typography>
                                            <Typography className={classes.greySubText} variant="body1">
                                                {infoData?.contact}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography className={classes.blueHead} variant="body1">
                                                Date of Birth
                                            </Typography>
                                            <Typography className={classes.greySubText} variant="body1">
                                                {infoData?.dob ? dateFormat({ dateString: infoData?.dob || '' }) : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography className={classes.blueHead} variant="body1">
                                                Settlement Currency
                                            </Typography>
                                            <Typography className={classes.greySubText} variant="body1">
                                                {infoData?.currency}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider />
                                <Box className={classes.inTabRight}>
                                    <Grid container spacing={2} className={classes.sectionBody}>
                                        <Grid item xs={12}>
                                            <Typography className={classes.subHeaderText} variant="h2">
                                                <img src={RepresentativeIcon} alt={RepresentativeIcon} />
                                                Account Verification
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography className={classes.blueHead} variant="body1">
                                                Sumsub Verification
                                            </Typography>
                                            <Typography className={classes.greySubText} variant="body1">
                                                {infoData?.sumsub_verified}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider />
                                <Box className={classes.inTabRight}>
                                    <Grid container spacing={2} className={classes.sectionBody}>
                                        <Grid item xs={12}>
                                            <Typography className={classes.subHeaderText} variant="h2">
                                                <img src={RepresentativeIcon} alt={RepresentativeIcon} />
                                                Bank Account Verification
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography className={classes.blueHead} variant="body1">
                                                Plaid Verification
                                            </Typography>
                                            <Typography className={classes.greySubText} variant="body1">
                                                {infoData?.teller_verified && infoData.updated_at
                                                    ? `Completed on 
                                                    ${dateFormat({ dateString: infoData.updated_at || '' })}, 
                                                    ${timeFormatterWithAmPm({ timeString: infoData.updated_at || '' })}`
                                                    : 'NA (Opted for Manual Input)'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                {!infoData?.teller_verified
                                && (
                                    <>
                                        <Divider />
                                        <Box className={classes.inTabRight}>
                                            <Grid container spacing={2} className={classes.sectionBody}>
                                                <Grid item xs={2}>
                                                    <Typography className={classes.blueHead} variant="body1">
                                                        Currency
                                                    </Typography>
                                                    <Typography className={classes.greySubText} variant="body1">
                                                        {infoData?.currency}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography className={classes.blueHead} variant="body1">
                                                        Account Description
                                                    </Typography>
                                                    <Typography className={classes.greySubText} variant="body1">
                                                        {infoData?.account_description}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography className={classes.blueHead} variant="body1">
                                                        Banking Instruction
                                                    </Typography>
                                                    <Typography className={classes.greySubText} variant="body1">
                                                        Wire transfer
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} />
                                                <Grid item xs={2}>
                                                    <Typography className={classes.blueHead} variant="body1">
                                                        Bank Address
                                                    </Typography>
                                                    <Typography className={classes.greySubText} variant="body1">
                                                        {infoData?.bank_address}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography className={classes.blueHead} variant="body1">
                                                        Institution Number
                                                    </Typography>
                                                    <Typography className={classes.greySubText} variant="body1">
                                                        {infoData?.institute_number}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography className={classes.blueHead} variant="body1">
                                                        Transit Number
                                                    </Typography>
                                                    <Typography className={classes.greySubText} variant="body1">
                                                        {infoData?.transit_number}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} />
                                                <Grid item xs={2}>
                                                    <Typography className={classes.blueHead} variant="body1">
                                                        Account Number
                                                    </Typography>
                                                    <Typography className={classes.greySubText} variant="body1">
                                                        {infoData?.account_number}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography className={classes.blueHead} variant="body1">
                                                        Swift/BIC
                                                    </Typography>
                                                    <Typography className={classes.greySubText} variant="body1">
                                                        {infoData?.swift_bic}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography className={classes.blueHead} variant="body1">
                                                        Account Holder’s First Name
                                                    </Typography>
                                                    <Typography className={classes.greySubText} variant="body1">
                                                        {infoData?.account_holder_first_name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} />
                                                <Grid item xs={2}>
                                                    <Typography className={classes.blueHead} variant="body1">
                                                        Account Holder’s Last Name
                                                    </Typography>
                                                    <Typography className={classes.greySubText} variant="body1">
                                                        {infoData?.account_holder_last_name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography className={classes.copyButtonStyle} variant="body1">
                                                        Account Holder’s Email
                                                        <span>
                                                            <Tooltip title="Click to copy" placement="top">
                                                                <FileCopyOutlinedIcon />
                                                            </Tooltip>
                                                        </span>
                                                    </Typography>
                                                    <Typography className={classes.greySubText} variant="body1">
                                                        {infoData?.email}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography className={classes.blueHead} variant="body1">
                                                        Beneficiary’s Name
                                                    </Typography>
                                                    <Typography className={classes.greySubText} variant="body1">
                                                        {infoData?.beneficiary_name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} />
                                                <Grid item xs={2}>
                                                    <Typography className={classes.blueHead} variant="body1">
                                                        Beneficiary’s Address
                                                    </Typography>
                                                    <Typography className={classes.greySubText} variant="body1">
                                                        {infoData?.beneficiary_address}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </>
                                )}
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <ListOfTransactionsTab />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                                <ReferralsTab />
                            </CustomTabPanel>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    );
}

export default UserDetails;
